define("@ember-data/private-build-infra/deprecations", ["exports", "@ember-data/private-build-infra/current-deprecations"], function (_exports, _currentDeprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEPRECATE_CATCH_ALL = _exports.DEPRECATE_3_12 = void 0;

  function deprecationState(deprecationName) {
    // if we hit this at runtime and the deprecation exists it is always activated
    return deprecationName in _currentDeprecations.default;
  } // deprecations


  const DEPRECATE_CATCH_ALL = deprecationState('DEPRECATE_CATCH_ALL');
  _exports.DEPRECATE_CATCH_ALL = DEPRECATE_CATCH_ALL;
  const DEPRECATE_3_12 = deprecationState('DEPRECATE_3_12');
  _exports.DEPRECATE_3_12 = DEPRECATE_3_12;
});