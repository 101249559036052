define("ember-a11y-refocus/components/navigation-narrator", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/runloop", "@glimmer/tracking", "ember-a11y-refocus"], function (_exports, _component, _templateFactory, _component2, _object, _service, _runloop, _tracking, _emberA11yRefocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    tabindex="-1"
    class="ember-sr-only ember-sr-only-focusable"
    id="ember-a11y-refocus-nav-message"
  >
    {{this.navigationText}}
  </div>
  {{#if this.skipLink}}
    <a
      href={{this.skipTo}}
      class="ember-a11y-refocus-skip-link {{if this.isSkipLinkFocused 'active'}}"
      {{on 'focus' this.handleSkipLinkFocus}}
      {{on 'blur' this.handleSkipLinkFocus}}
      id="ember-a11y-refocus-skip-link"
    >
      {{this.skipText}}
    </a>
  {{/if}}
  */
  {
    "id": "c99tItwT",
    "block": "[[[10,0],[14,\"tabindex\",\"-1\"],[14,0,\"ember-sr-only ember-sr-only-focusable\"],[14,1,\"ember-a11y-refocus-nav-message\"],[12],[1,\"\\n  \"],[1,[30,0,[\"navigationText\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"skipLink\"]],[[[1,\"  \"],[11,3],[16,6,[30,0,[\"skipTo\"]]],[16,0,[29,[\"ember-a11y-refocus-skip-link \",[52,[30,0,[\"isSkipLinkFocused\"]],\"active\"]]]],[24,1,\"ember-a11y-refocus-skip-link\"],[4,[38,1],[\"focus\",[30,0,[\"handleSkipLinkFocus\"]]],null],[4,[38,1],[\"blur\",[30,0,[\"handleSkipLinkFocus\"]]],null],[12],[1,\"\\n    \"],[1,[30,0,[\"skipText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"on\"]]",
    "moduleName": "ember-a11y-refocus/components/navigation-narrator.hbs",
    "isStrictMode": false
  });

  let NavigationNarratorComponent = (_class = class NavigationNarratorComponent extends _component2.default {
    get skipLink() {
      return this.args.skipLink ?? true;
    }

    get skipTo() {
      return this.args.skipTo ?? '#main';
    }

    get skipText() {
      return this.args.skipText ?? 'Skip to main content';
    }

    get navigationText() {
      return this.args.navigationText ?? 'The page navigation is complete. You may now navigate the page content as you wish.';
    }

    get routeChangeValidator() {
      return this.args.routeChangeValidator ?? _emberA11yRefocus.defaultValidator;
    }

    constructor() {
      super(...arguments); // focus on the navigation message after render

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "isSkipLinkFocused", _descriptor2, this);

      this.router.on('routeDidChange', transition => {
        let shouldFocus = this.routeChangeValidator(transition);

        if (!shouldFocus) {
          return;
        }

        (0, _runloop.schedule)('afterRender', this, function () {
          document.body.querySelector('#ember-a11y-refocus-nav-message').focus();
        });
      });
    }

    handleSkipLinkFocus() {
      this.isSkipLinkFocused = !this.isSkipLinkFocused;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSkipLinkFocused", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleSkipLinkFocus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSkipLinkFocus"), _class.prototype)), _class);
  _exports.default = NavigationNarratorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavigationNarratorComponent);
});